import request from '@/api/request'

// 获取充值金额列表
export const getRechargeList = (data: any) => {
    return request({
        url: '/sysConfig/queryPayOption',
        method: 'post',
        data
    })
}

// 充值用户验证
export const checkUser = (data: any) => {
    return request({
        url: '/deseUserCdkey/checkUser',
        method: 'post',
        data
    })
}

// 微信支付域名获取
export const getWeChatDomain = (data: any) => {
    return request({
        url: '/wxPay/payOfDomain',
        method: 'post',
        data
    })
}

// 创建订单微信支付
export const wechatPay = (url: string, data: any) => {
    return request({
        url: url,
        method: 'post',
        data
    })
}

// 微信支付订单状态查询
export const getWechatOrderStatus = (data: any) => {
    return request({
        url: '/wxPay/orderStatus',
        method: 'post',
        data
    })
}

// 支付宝支付域名获取
export const getAliPayDomain = (data: any) => {
    return request({
        url: '/aliPay/payOfDomain',
        method: 'post',
        data
    })
}

// 人机验证
export const downloadCaptcha = (data: any) => {
    return request({
        url: '/pagedDownLog/downCaptcha',
        method: 'get',
        params: data
    })
}
